import React from "react";
import { withRouter } from "../../../components/Reusable/WithRouter";
import { connect } from "react-redux";
import Common from "../../../hoc/Common.hoc";
import CustomerFooter from "../../../components/layout/CustomerFooter";
import MrHeader from "../../../components/layout/MR/MrHeader";
import MrAction from "../../../redux/actions/Mr.action";
import MrService from "../../../services/Mr.service";
import { format, toDate } from 'date-fns';
import $ from "jquery";
import CustomDatatable from "../../../components/Datatable/CustomDatatable.component";
import moment from "moment";
import StockistHeader from "../../../components/layout/Stockist/StockistHeader";
import StockistService from "../../../services/Stockist.service";
import OtherService from "../../../services/OtherService";
import OrderAction from "../../../redux/actions/Order.action";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS
import CountryService from '../../../services/Country.service';
import Dropdown from '../../../components/SearchableDropdown/Dropdown.component';
import CommonAction from "../../../redux/actions/Common.action";
import DropdownDoctor from "../../../components/SearchableDropdown/DropdownDoctor.component";
import OrderCancelModal from "../../../components/Order/OrderCancelModal";
import Summary from "../../../components/Summary/Summary.component";
import { ExportToExcel } from '../../../components/Excel/ExportToExcel'
import config from "../../../config/emrok.config";
import StockistAction from "../../../redux/actions/Stockist.action";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
class StockistList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            stockistdetails: null,
            stockistState: null,
            stockistid: "",
            stockistList: [],
            stockistinfo: "",
            stockistdetailsList: [],
            countries: [],
            states: [],
            towns: [],
            showTowns: false,
            selectedState: "",
            selectedTown: "",
            selectedDoctor: "",
            fromdate: "",
            todate: "",
            currentDate: this.getCurrentDate(),
            doctorList: [],
            isSearch: false,
            isShowCancelModal: false,
            cancelOrderId: null,
            cancelReason: "",
            mobileno: "",
            summaryCols: ["Total Registrations", "Total Orders", "Orders Pending", "Orders Delivered"],
            summaryDetails: null,
            setorderStatus: "",
            exportData: null,
            fileName: "Stockist_Data",
            exportDropdown: [
                {
                    id: 1,
                    label: "Export Current View",
                    value: 1
                },
                {
                    id: 2,
                    label: "Export All Data",
                    value: 2
                }
            ],
            selectedExportItem: null
        }
        this.copyState = this.state
        this.pageInfo = null
    }

    static getDerivedStateFromProps(props, state) {
        return {
            stockistdetails: props._id || null,
            summaryDetails: props.summaryDetails || null,
            stockistState: props.stockistState || null
        }
    }
    getCurrentDate() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(currentDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    getSummary = async (stockist_id = null) => {
        if (stockist_id) {
            // let payload = {
            //     type: "STOCKIST",
            //     id: stockist_id
            // }
            const { fromdate, todate, selectedState, selectedTown, selectedDoctor, setorderStatus } = this.state.stockistState;
            const selectedOnlyDateFrom = fromdate ? moment(fromdate).format('YYYY-MM-DD') : "";
            const selectedOnlyDateTo = todate ? moment(todate).format('YYYY-MM-DD') : "";
            let payload = {
                type: "STOCKIST",
                id: stockist_id,
                fromDate: fromdate ? selectedOnlyDateFrom : "",
                toDate: todate ? selectedOnlyDateTo : "",
                stateName: selectedState,
                town: selectedTown,
                doctor_id: selectedDoctor,
                orderStatus: setorderStatus
            };
            OtherService.getSummary(payload).then(data => {
                console.log(data, "getSummary");
            }).catch(err => {
                console.log(err, "getSummary err");
            })
        }
    }

    clickFn = async (index) => {
        const stockistdetails = this.props.stockistdetails;
        if (stockistdetails) {
            // let payload = await {
            //     stockist_id: stockistdetails._id
            // }
            let payload = {};
            let orderstatus = "";
            const { fromdate, todate, selectedState, selectedTown, selectedDoctor, setorderStatus } = this.state.stockistState;
            const selectedOnlyDateFrom = fromdate ? moment(fromdate).format('YYYY-MM-DD') : "";
            const selectedOnlyDateTo = todate ? moment(todate).format('YYYY-MM-DD') : "";
            if (index > 1) {
                payload = {
                    stockist_id: stockistdetails._id,
                    fromDate: fromdate ? selectedOnlyDateFrom : "",
                    toDate: todate ? selectedOnlyDateTo : "",
                    stateName: selectedState,
                    town: selectedTown,
                    doctor_id: selectedDoctor,
                    orderStatus: setorderStatus
                };
            }

            if (index === 0 || index === 1) {
                payload["orderStatus"] = ""
                orderstatus = ""
                this.setState({ setorderStatus: "" })
            }
            else if (index === 2) {
                payload["orderStatus"] = await "pending"
                orderstatus = "pending"
                this.setState({ setorderStatus: "pending" })

            }
            else if (index === 3) {
                payload["orderStatus"] = await "delivered"
                orderstatus = "delivered"
                this.setState({ setorderStatus: "delivered" })
            }
            const stockistState = this.state.stockistState;
            stockistState.setorderStatus = orderstatus;
            stockistState.stockistdetailsList = [];
            this.props.setStateData(stockistState);
            this.getStockistDetails(payload)
        }
    }
    formatData(auditdata = null, pageInfo = null) {
        console.log(auditdata, auditdata.length, "auditdata")
        let formatedArr = [];
        let allfeedback = [];
        let feedbackString = "";
        for (let i = 0; i < auditdata.length; i++) {
            const materials = auditdata[i].products.map(product => product.name);
            const materialString = materials.join(', ');
            const totalqty = auditdata[i].products.reduce((totalQuantity, product) => totalQuantity + product.quantity, 0);
            console.log(materials, materialString, totalqty, "popopopopo")
            console.log(auditdata, "jhjhjauditdatahjhjh");
            try {
                formatedArr.push({
                    "Order No.": auditdata[i].order_id || "",
                    "Order Date": auditdata[i].createdAt ? moment(auditdata[i].createdAt).format('DD-MM-YYYY') : "",
                    "Delivery Date": auditdata[i].deliveryDate ? moment(auditdata[i].deliveryDate).format('DD-MM-YYYY') : "",
                    // "Registration No.": auditdata[i].customer.patient_id || "",
                    // "Registration Date": auditdata[i].customer.createdAt || "",
                    "Patient Name": auditdata[i].customer.name || "",
                    "Patient Pin Code": auditdata[i].address.pin || "",
                    "Patient State": auditdata[i].address.state || "",
                    "Patient City": auditdata[i].address.town || "",
                    "Dr. Name": auditdata[i].doctor.drName || "",
                    // "Dr. Pin Code": auditdata[i].doctor.pin || "",
                    "Product Name": materialString,
                    "Product Qty.": totalqty + (auditdata[i].free_pen_quantity ? auditdata[i].free_pen_quantity : 0),
                    "Order Value": auditdata[i].total || "",
                    "Free Gift": auditdata[i].free_pen_quantity || 0,
                    // "Stockist Name": auditdata[i].stockist ? auditdata[i].stockist.name : "" || "",
                    // "Stockist Code": auditdata[i].stockist ? auditdata[i].stockist.stockist_code : "" || "",
                    "Payment Status": auditdata[i].payment_status ? auditdata[i].payment_status : "",
                    // "Prescription": auditdata[i].prescription ? auditdata[i].prescription.filepath : "",
                    // "Invoice": "",
                    "Order Status": auditdata[i].orderStatus || ""
                })
                console.log(formatedArr, 8787878787);
            } catch (error) {
                console.log(error, 8787878787);
            }
        }
        console.log(formatedArr, "formatedArr111");
        if (pageInfo && formatedArr.length) {
            let response = []
            const limit = pageInfo.rowsPerPage;
            const offset = (pageInfo.currentPage - 1) * pageInfo.rowsPerPage;
            response = formatedArr.slice(offset, offset + limit)
            return response
        }

        return formatedArr;

    }
    getDefaultTown = (alldata) => {
        // console.log(alldata, "alldata");
        let data = [];
        const allCustomerAddress = alldata.map(item => item.address.town);
        // Create a Set to automatically remove duplicates
        const uniqueTownsSet = new Set(allCustomerAddress);
        // Convert the Set back to an array (if needed)
        const uniqueTowns = Array.from(uniqueTownsSet);
        console.log(allCustomerAddress, "alldata allCustomerAddress");
        console.log(uniqueTowns, "alldata uniqueTowns");
        for (let i = 0; i < uniqueTowns.length; i++) {
            //  console.log(allCustomerAddress[i], "allcustomer123")
            data.push({ countryCode: "", isoCode: "", label: uniqueTowns[i], latitude: "", longitude: "", name: uniqueTowns[i], value: uniqueTowns[i] })
            // data[i]["label"] = allCustomerAddress[i]
            // data[i]["value"] = allCustomerAddress[i]
        }
        setTimeout(() => {
            this.setState({ towns: data })
            const stockistState = this.state.stockistState;
            stockistState.towns = data;
            this.props.setStateData(stockistState);
            // this.populateStates(countryObj.isoCode)	
        }, 500);
        // console.log(data, "allCustomerAddress123");
    }

    getStockistDetails = (details) => {
        this.setState({ stockistdetailsList: [] }, () => {
            StockistService.getStockistDetails(details).then(data => {
                console.log(data.data, "Stockist details 2");

                if (data.data.success && data.data.data.callcenterdetails !== '') {
                    console.log(data.data.data.callcenterdetails, "okkkkk");


                    const formatedData = this.formatData(data.data.data.callcenterdetails);

                    console.log(formatedData, "formatedData")

                    this.setState({ exportData: formatedData });

                    const currentDateTime = moment().format('DD-MM-YYYY H:m:s');
                    const filename = "Stockist_Data" + currentDateTime;
                    this.setState({ filename: filename });



                    // if(!this.state.isSearch){
                    const doctors = data.data.data.callcenterdetails.map(item => item.doctor);
                    this.getDoctors(doctors);
                    // this.setState({ doctorList: data.data.data.doctorinfo });
                    this.setState({ stockistdetailsList: data.data.data.callcenterdetails });

                    this.getDefaultTown(data.data.data.callcenterdetails)

                    const stockistState = this.state.stockistState;
                    stockistState.exportData = formatedData;
                    stockistState.filename = filename;
                    // stockistState.stockistdetailsList = data.data.data.callcenterdetails;

                    if (stockistState.stockistdetailsList.length) {
                        stockistState.stockistdetailsList = stockistState.stockistdetailsList;
                    } else {
                        stockistState.stockistdetailsList = data.data.data.callcenterdetails;
                    }

                    this.props.setStateData(stockistState);
                    // }
                }
                // this.setState({mrdetailsList:data.data.data.mrdetails});

            }).catch(err => {

                console.log(err, "err")
            })
        })
    }

    componentDidMount() {

        const stockistdetails = this.props.stockistdetails;
        if (stockistdetails) {
            const stockist_id = stockistdetails._id;
            let details = {
                stockist_id: stockist_id
            };
            console.log(details, "stockist details 1",);
            this.getSummary(stockist_id)
            StockistService.getStockistInfo(details).then(data => {
                console.log(data.data, "stockist info");

                if (data.data.success && data.data.data.stockistinfo !== '') {
                    console.log(data.data.data.stockistinfo[0], "okkkkk");

                    this.setState({ stockistinfo: data.data.data.stockistinfo[0] });
                    const stockistState = this.state.stockistState;
                    stockistState.stockistinfo = data.data.data.stockistinfo[0];
                    this.props.setStateData(stockistState);
                }



                // this.setState({mrdetailsList:data.data.data.mrdetails});

            }).catch(err => {

                console.log(err, "err")
            })
            this.getStockistDetails(details)
            let detailsBody = {
                id: stockist_id,
                type: "stockist",
            };
            // MrService.getDoctorsByMr(detailsBody).then(data => {
            //     console.log(data.data, "doctordetailsbymr123");

            //     if (data.data.success && data.data.data.doctorinfo !== '') {
            //         console.log(data.data, "doctorokkk1233");
            //         // const doctors = data.data.data.mrdetails.map(item => item.doctor);
            //         // this.setState({ doctorList: data.data.data.doctorinfo });
            //         // console.log(doctors, "alldoctors list");
            //         // this.getDoctors(data.data.data.doctorinfo);
            //         // this.setState({ mrdetailsList: data.data.data.mrdetails });
            //         // this.setState({ doctorList: data.data.data.doctorinfo });
            //     }
            //     // this.setState({mrdetailsList:data.data.data.mrdetails});

            // }).catch(err => {

            //     console.log(err, "err")
            // })

            setTimeout(() => {
                this.populateStates("IN")
                // this.populateStates(countryObj.isoCode)	
            }, 500);
        } else {
        }
    }

    populateStates = (isoCode = null) => {
        if (isoCode) {
            CountryService.getStates(isoCode).then(data => {
                //console.log(data, "states")
                if (data.success) {
                    const stockistState = this.state.stockistState;
                    stockistState.states = data.states;
                    console.log(stockistState.states, 6666666);
                    this.props.setStateData(stockistState);
                    this.setState({ states: data.states, towns: [] }, async () => {
                        this.setState({ showTowns: false })
                        let _state = this.state.stockistState.selectedState || null
                        console.log(_state, this.state.stockistState.states, "jhgjhgjhg");
                        if (_state) {
                            let stateObj = null
                            for (let i = 0; i < this.state.stockistState.states.length; i++) {
                                if (this.state.stockistState.states[i].name === _state) {
                                    stateObj = this.state.stockistState.states[i]
                                }
                            }
                            console.log(stateObj, _state, 1234545677);
                            console.log("stateObj found", stateObj)
                            // if (stateObj) {
                            setTimeout(() => {
                                this.populateTowns(stateObj.countryCode, stateObj.isoCode)
                            }, 500);
                            // }
                        }
                    })

                    setTimeout(() => {
                        let stateDetails = this.state.stockistState.states;
                        const findIfExistAll = stateDetails.find(item => item.name === 'All');
                        console.log(findIfExistAll, 33333);
                        if (!findIfExistAll) {
                            const allOption = {
                                "name": "All",
                                "isoCode": null,
                                "countryCode": null,
                                "latitude": null,
                                "longitude": null,
                                "label": "All",
                                "value": "all"
                            };


                            console.log(this.state.stockistState.states, "details state")
                            // Create a new array with the "All" option at the beginning
                            let optionsWithAll = [allOption, ...stateDetails];
                            // let optionsWithAll1 = [option2, ...optionsWithAll];
                            console.log(optionsWithAll, "details state all")
                            this.setState({ states: optionsWithAll })

                            const stockistState = this.state.stockistState;
                            stockistState.states = optionsWithAll;
                            this.props.setStateData(stockistState);
                        }

                    }, 500);
                }
            })
        }
    }

    populateTowns = (countryCode = null, stateCode = null) => {
        console.log(countryCode, stateCode, "countryCode stateCode")
        if (countryCode && stateCode) {
            CountryService.getTowns(countryCode, stateCode).then(data => {
                console.log(data, "towns")
                // setTimeout(() => {
                if (data.success) {
                    this.setState({ towns: data.towns }, () => this.setState({ showTowns: true }))

                    setTimeout(() => {
                        let stateDetails = this.state.towns;
                        const allOption1 = {
                            "name": "All",
                            "isoCode": null,
                            "countryCode": null,
                            "latitude": null,
                            "longitude": null,
                            "label": "All",
                            "value": "all"
                        };
                        console.log(this.state.towns, "details state")
                        // Create a new array with the "All" option at the beginning
                        let optionsWithAll1 = [allOption1, ...stateDetails];
                        console.log(optionsWithAll1, "details town all")
                        this.setState({ towns: optionsWithAll1 })

                        const stockistState = this.state.stockistState;
                        stockistState.towns = optionsWithAll1;
                        this.props.setStateData(stockistState);
                    }, 500);
                }
                // }, 2000);
            })
        }
    }

    setOrderHistoryData = (data) => {
        console.log(data, "89898767676");
        let resp = []
        let allfeedback = [];
        let feedbackString = "";
        for (let i = 0; i < data.length; i++) {
            feedbackString = "";
            allfeedback = [];
            const materials = data[i].products.map(product => product.name);
            const totalqty = data[i].products.reduce((totalQuantity, product) => totalQuantity + product.quantity, 0);
            const materialString = materials.join(', ');
            resp.push({
                _id: data[i]._id,
                customer_id: data[i].customer._id,
                customer_name: data[i].customer.name,
                customer_mobile: data[i].customer.mobileno,
                customer_address: (data[i].address ? data[i].address.addressLine1 : "") + "," + (data[i].address ? data[i].address.town : "") + "," + (data[i].address ? data[i].address.state : "") + "," + (data[i].address ? data[i].address.country : "") + "," + (data[i].address ? data[i].address.pin : ""),
                customer_state: (data[i].address ? data[i].address.state : ""),
                customer_city: (data[i].address ? data[i].address.town : ""),
                order_id: data[i].order_id,
                allproducts: data[i].products,
                order_status: data[i].orderStatus ? data[i].orderStatus : "",
                order_date: data[i].createdAt ? data[i].createdAt : "",
                delivery_date: data[i].deliveryDate ? data[i].deliveryDate : "",
                doctor_name: data[i].doctor.drName,
                // mr_name: data[i].mr[0].name,
                stockist_name: data[i].stockist ? data[i].stockist.name : "",
                stockist_code: data[i].stockist ? data[i].stockist.stockist_code : "",
                product_code: materialString,
                total_product_qty: totalqty + (data[i].free_pen_quantity ? data[i].free_pen_quantity : 0),
                total: data[i].total ? data[i].total : "",
                // feedback: feedbackStringFinal,
                prescription_path: data[i].prescription ? data[i].prescription.filepath : "",
                fileExtention: data[i].prescription ? data[i].prescription.filepath.split('.')[1] : "",
                payment_status: data[i].payment_status ? data[i].payment_status : "",
                payment_type: data[i].payment_type ? data[i].payment_type : "",
                registration_no: data[i].customer.patient_id || "",
                registration_date: data[i].customer.createdAt || "",
                customer_pin: data[i].address.pin || "",
                doctor_pin: data[i].doctor.pin || "",
                free_pen: data[i].free_pen_quantity || 0,
                invoice: ""
            })
        }
        console.log(resp, data, "4554545454")
        return resp
    }

    componentWillUnmount() {
        if (this.dataTable) {
            this.dataTable.destroy(true);
        }
    }
    handleCancelOrder = (event) => {
        const orderId = event.target.name;
        const mobileno = event.target.id;
        // alert(orderId);
        this.setState({ isShowCancelModal: true, cancelOrderId: orderId, mobileno: mobileno });
        // this.setState({ cancelOrderId: orderId });
        // this.setState({ mobileno: mobileno });

        const stockistState = this.state.stockistState;
        stockistState.isShowCancelModal = true;
        stockistState.cancelOrderId = orderId;
        stockistState.mobileno = mobileno;
        this.props.setStateData(stockistState);


    }
    handleOkClick = () => {
        const orderId = this.state.cancelOrderId;
        const stockistdetails = this.props.stockistdetails;
        const stockist_id = stockistdetails._id;
        const userType = this.props.stockistdetails.userType;
        let details = {
            order_id: orderId,
            user_id: stockist_id,
            user_type: userType,
            reason: this.state.cancelReason,
            mobileno: this.state.mobileno

        };
        let body = {
            stockist_id: stockist_id
        }

        if (this.state.cancelReason != "") {
            OtherService.cancelOrder(details).then(data => {
                console.log(data.data, "cancel order");

                if (data.data.success) {
                    console.log(data.data, "data.data")
                    this.setState({ stockistdetailsList: [] });
                    StockistService.getStockistDetails(body).then(data => {
                        console.log(data.data, "Stockist details 2");

                        if (data.data.success && data.data.data.stockistdetails !== '') {
                            console.log(data.data, "okkkkk");
                            // if(!this.state.isSearch){
                            this.setState({ stockistdetailsList: data.data.data.stockistdetails });

                            const stockistState = this.state.stockistState;
                            stockistState.stockistdetailsList = data.data.data.stockistdetails;
                            this.props.setStateData(stockistState);
                            // }
                        }

                    }).catch(err => {

                        console.log(err, "err")
                    })

                    this.props.hoc.customAlert("Order rejected successfully.", true)

                }
                // this.setState({mrdetailsList:data.data.data.mrdetails});

            }).catch(err => {
                this.props.hoc.customAlert("Order rejected successfully.", true)
                console.log(err, "err")
            })
        } else {
            this.props.hoc.customAlert("Select reject reason", false);
        }
        // alert(callcenter_id);
    }

    handleRadioClick = (event) => {
        // alert("ok")
        //  alert(event.target.value);

        this.setState({ cancelReason: event.target.value });

        const stockistState = this.state.stockistState;
        stockistState.cancelReason = event.target.value;
        this.props.setStateData(stockistState);
    }

    getDoctors = (doctors) => {
        console.log(doctors, "getdoctors")
        // Create an array to store the grouped data
        const groupedData = [];
        const finalData = [];
        // Create an object to keep track of seen doctor._id values
        const seenDoctorIds = {};
        // Use forEach to iterate through the data
        doctors.forEach((currentValue) => {
            const doctorId = currentValue._id;
            // Check if we've already seen this doctor._id
            if (!seenDoctorIds[doctorId]) {
                // Add the current value to the groupedData array
                groupedData.push(currentValue);
                // Mark the doctor._id as seen
                seenDoctorIds[doctorId] = true;
            }
        });

        for (let index = 0; index < groupedData.length; index++) {
            finalData[index] = {
                "name": groupedData[index].name,
                "label": groupedData[index].name,
                "value": groupedData[index]._id,
                "id": groupedData[index]._id,
            };
        }
        const allOption = {
            "name": "All",
            "label": "All",
            "value": "All",
            "id": "All"
        };
        let optionsWithAll = [allOption, ...finalData];
        console.log(finalData, "finalData");
        // Now 'groupedData' contains unique doctors with a regular index
        this.setState({ doctorList: optionsWithAll });
        const stockistState = this.state.stockistState;
        if (stockistState.doctorList.length == 0) {
            stockistState.doctorList = optionsWithAll;
            this.props.setStateData(stockistState);
        }
        console.log(groupedData, "groupedData")
        // Now 'groupedData' contains the data grouped by doctor._id

    }

    handleTrackOrder = (event) => {

        // const allOrder = this.props.OrderReducer.allOrders;
        const targetId = event.target.name;
        console.log(targetId, "target ID");
        try {
            OtherService.getOrderDetails({ _id: targetId }).then(data => {
                console.log(data.data, "order details 123456789");
                if (data.data.success) {
                    console.log(data.data.data.order, "okkkkk 123456789");
                    this.props.saveSelectedOrders(data.data.data.order)
                    this.props.navigate('/stockist/track-info-order');
                    this.props.setUserType('stockist');
                    // this.setState({mrdetailsList:data.data.data.mrdetails});
                }
            })
        } catch (error) {

        }

    }
    changeValFromDate = async (_date) => {
        if (isNaN(new Date(_date).getTime())) {
            console.error("Invalid from date");
            return;
        }

        let date = new Date(_date);
        console.log(date, "fromdate");
        this.setState({ fromdate: date });
        const stockistState = { ...this.state.stockistState, fromdate: date };
        this.props.setStateData(stockistState);
    };

    changeValToDate = async (_date) => {
        if (isNaN(new Date(_date).getTime())) {
            console.error("Invalid to date");
            return;
        }

        let date = new Date(_date);
        console.log(date, "todate");
        this.setState({ todate: date });
        const stockistState = { ...this.state.stockistState, todate: date };
        this.props.setStateData(stockistState);
    };

    searchFilter = (event) => {
        this.setState({ stockistdetailsList: [] });
        const stockistState = this.state.stockistState;
        stockistState.stockistdetailsList = [];
        this.props.setStateData(stockistState);

        const stockistdetails = this.props.stockistdetails;
        const stockist_id = stockistdetails._id;
        const { fromdate, todate, selectedState, selectedTown, selectedDoctor, setorderStatus } = this.state;

        const selectedOnlyDateFrom = fromdate ? moment(fromdate).format('YYYY-MM-DD') : "";
        const selectedOnlyDateTo = todate ? moment(todate).format('YYYY-MM-DD') : "";

        let details = {
            stockist_id: stockist_id,
            fromDate: fromdate ? selectedOnlyDateFrom : "",
            toDate: todate ? selectedOnlyDateTo : "",
            stateName: selectedState,
            town: selectedTown,
            doctor_id: selectedDoctor,
            orderStatus: setorderStatus
        };
        console.log(details, 2222)

        if (fromdate != "" && todate == "") {
            this.props.hoc.customAlert("Please select both fromdate and todate", false);
        } else if (fromdate == "" && todate != "") {
            this.props.hoc.customAlert("Please select both fromdate and todate", false);
        } else {

            StockistService.getStockistDetails(details).then(data => {
                console.log(data.data, "Stockist details filter");

                if (data.data.success && data.data.data.callcenterdetails !== '') {
                    console.log(data.data, "okkkkk filter");


                    const formatedData = this.formatData(data.data.data.callcenterdetails);
                    console.log(formatedData, "formatedData")
                    this.setState({ exportData: formatedData });
                    const currentDateTime = moment().format('DD-MM-YYYY H:m:s');
                    const filename = "Stockist_Data" + currentDateTime;
                    this.setState({ filename: filename });
                    // if(!this.state.isSearch){
                    const doctors = data.data.data.callcenterdetails.map(item => item.doctor);
                    this.getDoctors(doctors);

                    this.setState({
                        stockistdetailsList: data.data.data.callcenterdetails
                    });
                    this.setState({ isSearch: true })


                    const stockistState = this.state.stockistState;
                    stockistState.exportData = formatedData;
                    stockistState.filename = filename;
                    stockistState.stockistdetailsList = data.data.data.callcenterdetails;
                    stockistState.isSearch = true;
                    this.props.setStateData(stockistState);
                    this.getSummary(stockist_id)
                } else {

                    this.getSummary(stockist_id)
                }



                // this.setState({mrdetailsList:data.data.data.mrdetails});

            }).catch(err => {

                console.log(err, "err")
            })
        }

        console.log("search filter");
    }

    changeDoctor = (event) => {
        const doctorId = event.target.value;
        this.setState({ selectedDoctor: doctorId });
        console.log(doctorId, "doctorId");
        const stockistState = this.state.stockistState;
        stockistState.selectedDoctor = doctorId;
        this.props.setStateData(stockistState);
    }

    downloadPdf = async (event) => {
        const order_id = event.target.id;
        const downloadUrl = config.serviceUrl + "/images/sap/in/Invoice/" + order_id + ".pdf"; // Replace with the actual URL or path of the file
        try {
            const response = await fetch(downloadUrl);
            if (response.status === 200) {
                window.open(downloadUrl, '_blank');
                console.log("present")
            } else {
                // alert("not present")
                this.props.hoc.customAlert("Invoice not yet generated.", false)
                console.log("not present")
            }
        } catch (error) {
            window.open(downloadUrl, '_blank');
            // Handle network or other errors
            console.log("error");
        }


        // alert(order_id);
    }

    exportXLSX = (item = null) => {
        console.log(item, this.pageInfo, "ioioioioi");
        if (item && this.pageInfo) {
            console.log(this.pageInfo, "898989898 adkladsjlkj", item, this.state.callcenterdetails);
            let apiData = this.formatData(this.state.stockistdetailsList, item.id === 1 ? this.pageInfo : null)
            console.log(apiData, "adkladsjlkj");
            setTimeout(() => {
                // const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                // const fileExtension = ".xlsx";
                // const ws = XLSX.utils.json_to_sheet(apiData);
                // const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                // const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
                // const data = new Blob([excelBuffer], { type: fileType });
                // FileSaver.saveAs(data, "Order_Data" + fileExtension);
                const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                const fileExtension = ".xlsx";
                const ws = XLSX.utils.json_to_sheet(apiData);
                const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
                const data = new Blob([excelBuffer], { type: fileType });

                // Create a link element and trigger the download
                const link = document.createElement("a");
                link.href = URL.createObjectURL(data);
                link.download = "Order_Data" + fileExtension;
                document.body.appendChild(link);
                link.click(); // Trigger the download
                document.body.removeChild(link);
            }, 1000);
        }
    }

    handleApproveOrder = (event) => {

        this.props.hoc.customAlert('Are you sure you want to approved?', true, "", true, true, () => {

            const orderId = event.target.name;
            const filteredItem = this.state.stockistState.stockistdetailsList.find(item => item.order_id == orderId);
            let details = {
                order_id: orderId,
                stockist_id: filteredItem._id,
                stockist_email: filteredItem.stockist.email,
                mrname: filteredItem.mr.name,
                doctorname: filteredItem.doctor.name,
                customername: filteredItem.customer.name
            }
            console.log(orderId, "filteredItem orderid")
            console.log(this.state.stockistState.stockistdetailsList, "filteredItem alldata");
            console.log(filteredItem, "filteredItem");

            if (orderId) {
                StockistService.approvedPrescription(details).then(data => {
                    console.log(data.data, "approved");

                    if (data.data.success) {
                        console.log(data.data, "okkkkk approved");

                        const stockistdetails = this.props.stockistdetails;

                        let payload = {
                            stockist_id: stockistdetails._id
                        }
                        this.getStockistDetails(payload)
                        this.props.hoc.customAlert("Prescription approved succesfully.", true);
                    }

                }).catch(err => {

                    console.log(err, "err")
                })
            }
        }, () => {

        })

    }

    clearFilter = () => {

        this.setState({
            stockistdetails: null,
            stockistState: null,
            stockistid: "",
            stockistList: [],
            stockistinfo: "",
            stockistdetailsList: [],
            countries: [],
            towns: [],
            showTowns: false,
            selectedState: "",
            selectedTown: "",
            selectedDoctor: "",
            fromdate: "",
            todate: "",
            currentDate: this.getCurrentDate(),
            doctorList: [],
            isSearch: false,
            isShowCancelModal: false,
            cancelOrderId: null,
            cancelReason: "",
            mobileno: "",
            summaryDetails: null,
            setorderStatus: "",
            exportData: null,
            fileName: "Stockist_Data",
        })
        const stockist_details = this.props.stockistdetails;
        const stockist_id = stockist_details._id;
        let details = {
            stockist_id: stockist_id
        };

        const stockistDetails = this.state.stockistState;
        stockistDetails.stockistdetails = null
        stockistDetails.stockistState = null
        stockistDetails.stockistid = ""
        stockistDetails.stockistList = []
        stockistDetails.stockistinfo = ""
        stockistDetails.stockistdetailsList = []
        stockistDetails.countries = []
        stockistDetails.towns = []
        stockistDetails.showTowns = false
        stockistDetails.selectedState = ""
        stockistDetails.selectedTown = ""
        stockistDetails.selectedDoctor = ""
        stockistDetails.fromdate = ""
        stockistDetails.todate = ""
        stockistDetails.currentDate = this.getCurrentDate()
        stockistDetails.doctorList = []
        stockistDetails.isSearch = false
        stockistDetails.isShowCancelModal = false
        stockistDetails.cancelOrderId = null
        stockistDetails.cancelReason = ""
        stockistDetails.mobileno = ""
        stockistDetails.summaryDetails = null
        stockistDetails.setorderStatus = ""
        stockistDetails.exportData = null
        stockistDetails.fileName = "Stockist_Data"
        this.props.setStateData(stockistDetails);
        this.getStockistDetails(details)
        this.getSummary(stockist_id)
    }

    handleChangeDeliveryStatus = (event) => {

        this.props.hoc.customAlert('Are you sure you want to change delivery status?', true, "", true, true, () => {

            const orderId = event.target.id;
            // alert(orderId);
            console.log(orderId, "orderId123");
            const filteredItem = this.state.stockistState.stockistdetailsList.find(item => item.order_id == orderId);
            let details = {
                order_id: orderId,
            }
            console.log(orderId, "filteredItem orderid")
            console.log(this.state.stockistState.stockistdetailsList, "filteredItem alldata");
            console.log(filteredItem, "filteredItem");

            if (orderId) {
                StockistService.changeOrderDeliveryStatus(details).then(data => {
                    console.log(data.data, "approved");
                    if (data.data.success) {
                        console.log(data.data, "okkkkk approved");
                        const stockistdetails = this.props.stockistdetails;
                        let payload = {
                            stockist_id: stockistdetails._id
                        }
                        this.getStockistDetails(payload)
                        this.props.hoc.customAlert("Order status changed successfully.", true);
                    }
                }).catch(err => {
                    console.log(err, "err")
                })
            }
        }, () => {

        })
    }


    render = () => {
        let _data = this.setOrderHistoryData(this.state.stockistdetailsList)
        const { exportData, fileName, countries, states, towns, isLoading, showTowns, selectedState, selectedTown, fromdate, todate, doctorList, selectedDoctor } = this.state.stockistState;

        const { summaryCols, summaryDetails, exportDropdown, selectedExportItem } = this.state

        console.log(fromdate, todate, "towns details");
        return (

            <>

                <section className="landing-profl-detls">
                    <div className="container">
                        <StockistHeader />
                        {/* Row end */}
                        <div className="row">
                            <div className="landing-page-pt pt-3 pb-4">
                                <div className="container">
                                    <div className="mr-landing-page">
                                        <div className="landing-head-pt">
                                            {/* <h2>Stockist Landing page</h2> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>{" "}
                        {/* Row end*/}
                        <div className="landing-form-dtls">
                            <div className="row mb-25">
                                <div className="col-md-6">
                                    <h5 className="mb-20">Welcome {this.state.stockistState.stockistinfo.name} to Diabetes Golden Forum (Stockist)</h5>
                                </div>
                                {/* <div className="col-md-6">
                                    <div className="flotright marginright">
                                        <ExportToExcel apiData={exportData} fileName={fileName} />
                                    </div>
                                </div> */}

                                <div className='col-md-6'>
                                    <div className="flotright marginright" style={{ width: "180px" }}>
                                        {/* <ExportToExcel apiData={exportData} fileName={fileName} /> */}
                                        {
                                            exportDropdown.length &&
                                            <Dropdown
                                                items={exportDropdown}
                                                onSelect={(item) => {
                                                    console.log(item, "kasghak");
                                                    this.exportXLSX(item)
                                                }}
                                                value={selectedExportItem}
                                                isExport={true}
                                            />
                                        }
                                    </div>
                                </div>

                            </div>


                            <div className="row mt-30 mb-20">
                                <div className="col-md-2 mb-3">
                                    <label className="form-label lvl-clr-hd">Order From Date</label>
                                    <DatePicker
                                        selected={this.state.fromdate}
                                        onChange={(date) => this.changeValFromDate(date)}
                                        dateFormat="dd-MM-yyyy"
                                        className="form-control name-pt-ft"
                                        placeholderText="DD-MM-YYYY"
                                    />
                                </div>
                                <div className="col-md-2 mb-3">
                                    <label className="form-label lvl-clr-hd">Order To Date</label>
                                    <DatePicker
                                        selected={this.state.todate}
                                        onChange={(date) => this.changeValToDate(date)}
                                        dateFormat="dd-MM-yyyy"
                                        className="form-control name-pt-ft"
                                        placeholderText="DD-MM-YYYY"
                                        minDate={this.state.fromdate}
                                    />
                                </div>


                                <div className="col-md-3 mb-3">
                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">State </label>
                                    {
                                        states.length ?
                                            <Dropdown
                                                items={states}
                                                onSelect={(item) => {
                                                    if (item) {

                                                        this.setState({ selectedState: item.name })
                                                        if (item.name != "All" && item.value != "") {
                                                            const stockistState = this.state.stockistState;
                                                            stockistState.selectedState = item.name;
                                                            this.props.setStateData(stockistState);
                                                            this.populateTowns(item.countryCode, item.isoCode)
                                                        } else if (item.value == "") {
                                                            this.getDefaultTown(this.state.stockistState.stockistdetailsList)
                                                            this.setState({ selectedTown: "" })
                                                            const stockistState = this.state.stockistState;
                                                            stockistState.selectedTown = "";
                                                            this.props.setStateData(stockistState);
                                                        }
                                                        else {
                                                            this.setState({ towns: [] })
                                                            this.setState({ selectedTown: "" })

                                                            const stockistState = this.state.stockistState;
                                                            stockistState.towns = [];
                                                            stockistState.selectedTown = "";
                                                            this.props.setStateData(stockistState);
                                                        }
                                                    }
                                                }}
                                                value={selectedState}
                                            /> :
                                            <Dropdown
                                                items={states}
                                                value={selectedState}
                                            />
                                    }

                                </div>
                                <div className="col-md-3 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">City/Town </label>
                                    {
                                        towns.length ?
                                            <Dropdown
                                                items={towns}
                                                onSelect={(item) => {
                                                    if (item) {

                                                        this.setState({ selectedTown: item.name })
                                                        const stockistState = this.state.stockistState;
                                                        stockistState.selectedTown = item.name;
                                                        this.props.setStateData(stockistState);
                                                        //console.log(item, "item")
                                                    }
                                                }}
                                                value={selectedTown}
                                            /> :
                                            <Dropdown
                                                items={towns}
                                                value={selectedTown}
                                            />
                                    }

                                </div>

                                <div className="">
                                    <button type="button" className="landing-success-btn" onClick={(event) => this.searchFilter(event)}>
                                        Search</button>
                                    <button type="button" className="landing-clear-btn" onClick={(event) => this.clearFilter()}>
                                        Clear Filter</button>
                                </div>

                            </div>
                            {/* Summary table start */}
                            <Summary
                                columns={summaryCols}
                                data={[{
                                    totalNoOfReg: summaryDetails ? summaryDetails.totalNoOfReg || 0 : 0,
                                    totalNoOfOrder: summaryDetails ? summaryDetails.totalNoOfOrder || 0 : 0,
                                    totalNoOfOrderPending: summaryDetails ? summaryDetails.totalNoOfOrderPending || 0 : 0,
                                    totalNoOfOrderDelivered: summaryDetails ? summaryDetails.totalNoOfOrderDelivered || 0 : 0,
                                }]}
                                clickFn={this.clickFn}
                            />

                            <h5 className="mb-20">Details</h5>
                            <div className="table-responsive">
                                {
                                    _data && _data.length ?
                                        <CustomDatatable
                                            pagination={true}
                                            columns={[
                                                {
                                                    name: 'Order No.',
                                                },
                                                {
                                                    name: 'Order Date',
                                                },
                                                {
                                                    name: 'Delivery Date',
                                                },
                                                {
                                                    name: 'Patient Name',
                                                },
                                                {
                                                    name: 'Patient Pin Code',
                                                },
                                                {
                                                    name: 'Patient State',
                                                },
                                                {
                                                    name: 'Patient City',
                                                },
                                                {
                                                    name: 'Dr. Name',
                                                },

                                                {
                                                    name: 'Product Name',
                                                },
                                                {
                                                    name: 'Product Qty.',
                                                },
                                                {
                                                    name: 'Order Value',
                                                },
                                                {
                                                    name: 'Free Gift',
                                                },

                                                {
                                                    name: 'Payment Status',
                                                },
                                                {
                                                    name: 'Prescription',
                                                },
                                                {
                                                    name: 'Invoice',
                                                },
                                                {
                                                    name: 'Order Status',
                                                },
                                                {
                                                    name: 'Mark as Delivered',
                                                }
                                            ]}
                                            data={this.setOrderHistoryData(this.state.stockistdetailsList)}
                                            handleTrackOrder={this.handleTrackOrder}
                                            downloadPdf={this.downloadPdf}
                                            pageName="stockistlanding"
                                            handleChangeDeliveryStatus={this.handleChangeDeliveryStatus}
                                            sendPageInfo={(pageInfo) => {
                                                console.log(pageInfo, "sendPageInfo");
                                                this.pageInfo = pageInfo
                                            }}

                                        />
                                        : <p className="text-center">No Records Found</p>
                                }

                                <OrderCancelModal message="Are you sure want to reject?" handleOkClick={this.handleOkClick} handleRadioClick={this.handleRadioClick} cancelReasonData={this.state.cancelReason} />
                            </div>
                        </div>
                    </div>
                </section >
                <CustomerFooter />



            </>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUserType: (userType) => dispatch(CommonAction.setUserType(userType)),
        // dispatching plain actions
        // setMr: (mrdetails) => dispatch(MrAction.setMr(mrdetails))
        saveSelectedOrders: (selectedOrders) => dispatch(OrderAction.saveSelectedOrders(selectedOrders)),
        setStateData: (stockistState) => dispatch(StockistAction.setStateData(stockistState))
    }
}

const mapStateToProps = ({ CustomerReducer, OrderReducer, StockistReducer, CommonReducer }) => {
    const { customer } = CustomerReducer
    const { orders } = OrderReducer
    const { stockistdetails, stockistState } = StockistReducer
    const { summaryDetails } = CommonReducer

    console.log(customer, 123);
    console.log(stockistdetails, 123456);
    return {
        // dispatching plain actions
        // OrderListReducer: state.OrderListReducer,
        customer,
        orders,
        stockistdetails,
        summaryDetails,
        stockistState
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(StockistList)))