import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from '../../../components/Reusable/WithRouter';
import CustomerAction from "../../../redux/actions/Customer.action";
import CustomerHeader from "../../../components/layout/CustomerHeader";
import CustomerFooter from '../../../components/layout/CustomerFooter';
import ProductList from '../../../components/Customer/ProductList';
import ProductService from '../../../services/ProductService';
import OrderList from '../../../components/Order/OrderList';
import CustomerService from '../../../services/Customer.service';
import OrderAction from '../../../redux/actions/Order.action';
import OtherService from '../../../services/OtherService';
import Common from '../../../hoc/Common.hoc';
import CountryService from '../../../services/Country.service';
import CCAvenueService from '../../../services/CCAvenue.service';
import config from '../../../config/emrok.config';
import OrderPlacedModal from '../../../components/Order/OrderPlacedModal';
import { FREE_PEN_PRICE, FREE_PEN_QUANTITY, PRODUCT_LIMIT } from '../../../utils/Variables';
import $ from 'jquery'
import CallCenterService from '../../../services/CallCenter.service';

class PlaceOrder extends Component {
    constructor(props) {
        super(props)
        this.state = {
            discountvalue: "",
            couponList: [],
            couponListFinal: [],
            selectedValue: "",
            couponDiscount: "",
            isShowApplyBtn: true,
            product_quantities: [],
            reload: true,
            paymentOrder: {
                totalPayment: "",
                partialMinPayment: "",
                deliveryPayment: "",
                totalItem: "",
                discountamount: "",
                finalPaymentAfterDiscount: "",
                appliedDiscount: ""
            },
            productQuantity: {

            },
            products: [],
            order: {
                rx: "",
                serialNo: "",
                customer: "",
                products: [{
                    product: "",
                    quantity: "",
                    price: ""
                }],
                total: "",
                deliveryStatus: "",
                orderStatus: ""
            },
            common_products: [],
            isChecked: false,
            isCheckFreePen: false,
            registration: {
                "addressLine1": "",
                "pin": "",
                "country": "India",
                "state": "",
                "town": ""
            },
            countries: [{
                "name": "India",
                "label": "India",
                "title": "India",
                "value": "IN",
                "id": 123,
                "isoCode": "IN"
            }],
            states: [],
            towns: [],
            country: "India",
            states: [],
            state: "",
            towns: "",
            town: "",
            showTowns: false,
            addressLine1: "",
            paymentDetails: null,
            isOrderplaced: false,
            orderDetails: null,
            schemeDetails: [],
            freepenQuantity: 0,
            freepenPrice: 0,
            schemeCouponList: [],
            titleName: "Free Pen",
            coupon_code: null
        }
        this.productDecrement = this.productDecrement.bind(this);
        this.productIncrement = this.productIncrement.bind(this);
        this.placeOrder = this.placeOrder.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        return {
            common_products: props.ProductReducer ? props.ProductReducer.common_products ? props.ProductReducer.common_products : [] : [],
            paymentDetails: props.OrderReducer ? props.OrderReducer.paymentDetails ? props.OrderReducer.paymentDetails : null : null
        }
    }

    componentWillUnmount = () => {
        CallCenterService.hideLoader()
    }

    // Callback function to update the selectedValue state
    handleSelectChange = (item) => {
        console.log(item, "selectedValue");
        this.setState({ selectedValue: item });
    };

    calculateScheme = () => {
        const { paymentOrder, products, product_quantities, productQuantity, selectedValue, couponList, couponListFinal, schemeCouponList } = this.state;
        console.log('123334', productQuantity);
        let _products = [...products]
        let total_free_pen = 0, total_free_pen_price = 0
        if (selectedValue) {
            for (let i = 0; i < _products.length; i++) {
                if (_products[i].material == selectedValue.material) {
                    let quantity = product_quantities[i].quantity
                    if (quantity >= selectedValue.purchase_quantity) {
                        if (selectedValue.increase_quantity == 'YES') {
                            let free_pen = Math.floor(quantity / selectedValue.purchase_quantity) * selectedValue.free_quantity
                            let price = +selectedValue.free_value * free_pen
                            total_free_pen += free_pen
                            total_free_pen_price += price
                        }
                        else {
                            let free_pen = selectedValue.free_quantity
                            let price = +selectedValue.free_value * free_pen
                            total_free_pen += free_pen
                            total_free_pen_price += price
                        }
                    }
                }
            }
            console.log(total_free_pen, total_free_pen_price, "jasdhfjklashjkh");
            this.setState({ freepenQuantity: total_free_pen, freepenPrice: total_free_pen_price, isCheckFreePen: true, isShowApplyBtn: false })
        }
    }

    calculateCoupon = () => {
        const { paymentOrder, products, product_quantities, productQuantity, selectedValue, couponList, couponListFinal, schemeCouponList } = this.state;
        console.log('123334', paymentOrder);

        if (selectedValue) {
            let value = selectedValue ? selectedValue.name ? selectedValue.name : null : null

            console.log();
            const newProducts = products.map((product, i) => {
                return {
                    product_id: product._id,
                    price: product.price,
                    quantity: product_quantities[i].quantity,
                    name: product.name
                }
            })
            const _products = newProducts.filter(product => product.quantity != 0)

            if (!_products.length) {
                this.props.hoc.customAlert("Please select a product for applying coupon", false)
            } else if (value == "") {
                this.props.hoc.customAlert("Please select a valid coupon", false)
            } else {
                const selectedCoupon = couponListFinal.filter(item => item.code === value)

                console.log(selectedCoupon, "selectedCoupon 123");
                console.log(paymentOrder, "paymentOrder 1002")
                console.log(paymentOrder.finalPaymentAfterDiscount, "paymentOrder.finalPaymentAfterDiscount 1002")
                const finalPaymentAfterDiscount = paymentOrder.totalPayment;
                console.log(finalPaymentAfterDiscount, "finalPaymentAfterDiscount 1002");


                const couponDiscount = finalPaymentAfterDiscount * (selectedCoupon[0].value / 100)
                console.log(couponDiscount, "65656dd565");
                const afterCouponDiscount = finalPaymentAfterDiscount - couponDiscount;
                let newPaymentOrder = paymentOrder;
                newPaymentOrder['finalPaymentAfterDiscount'] = afterCouponDiscount;
                console.log(newPaymentOrder, "newPaymentOrder 123");
                this.setState({ paymentOrder: newPaymentOrder })
                this.setState({ couponDiscount: couponDiscount });
                this.setState({ isShowApplyBtn: false });
                console.log(selectedCoupon[0], "selectedCoupon 123")
                console.log(finalPaymentAfterDiscount, "finalPaymentAfterDiscount 123")
                console.log(couponDiscount, "couponDiscount 123")
                console.log(afterCouponDiscount, "afterCouponDiscount 123")
            }
        }
    }

    applyCoupon = () => {
        const { paymentOrder, products, product_quantities, productQuantity, selectedValue, couponList, couponListFinal, schemeCouponList } = this.state;
        console.log(selectedValue, schemeCouponList, "selectedValue123");
        console.log(couponList, "couponList");
        if (selectedValue) {

            console.log('12333');
            if (selectedValue.hasOwnProperty("increase_quantity")) {

                const newProducts = products.map((product, i) => {
                    return {
                        product_id: product._id,
                        price: product.price,
                        quantity: product_quantities[i].quantity,
                        name: product.name
                    }
                })
                const _products = newProducts.filter(product => product.quantity != 0)

                if (!_products.length) {
                    this.setState({ selectedValue: '' })
                    this.props.hoc.customAlert("Please select a product for applying scheme", false)
                } else {
                    let titleName = "";
                    if (selectedValue.scheme_code == 1) {
                        titleName = 'Free Cartridge'
                    } else {
                        titleName = 'Free Pen'
                    }
                    this.setState({ titleName: titleName })
                    // logic for scheme
                    this.calculateScheme()

                }

            }
            else {

                this.calculateCoupon()

            }
        }
    }

    removeCoupon = () => {
        const { paymentOrder, products, product_quantities, productQuantity, selectedValue, couponList, couponDiscount } = this.state;

        if (selectedValue) {
            if (selectedValue.hasOwnProperty("increase_quantity")) {
                // logic for scheme
                console.log('123334');

                if (selectedValue.increase_quantity == 'YES') {
                    this.setState({ freepenQuantity: 0, freepenPrice: 0, isCheckFreePen: false, isShowApplyBtn: true, selectedValue: "" })

                } else {
                    this.setState({ freepenQuantity: 0, freepenPrice: 0, isCheckFreePen: false, isShowApplyBtn: true, selectedValue: "" })
                }

            } else {
                const finalPaymentAfterDiscount = paymentOrder.finalPaymentAfterDiscount;
                const afterCouponDiscount = finalPaymentAfterDiscount + couponDiscount;
                let newPaymentOrder = paymentOrder;
                newPaymentOrder['finalPaymentAfterDiscount'] = afterCouponDiscount;
                console.log(finalPaymentAfterDiscount, "remove finalPaymentAfterDiscount");
                console.log(afterCouponDiscount, "remove afterCouponDiscount");
                console.log(newPaymentOrder, "remove newPaymentOrder");
                console.log(couponDiscount, "remove couponDiscount");
                console.log(newPaymentOrder, "remove newPaymentOrder 123");
                if (paymentOrder.totalPayment <= 0) {
                    newPaymentOrder['finalPaymentAfterDiscount'] = 0;
                }
                this.setState({ paymentOrder: newPaymentOrder })
                this.setState({ couponDiscount: "" });
                this.setState({ isShowApplyBtn: true });
                this.setState({ selectedValue: "" });

            }
        }


    }

    productPriceCalCulate = () => {
        const { products, selectedValue, couponListFinal } = this.state;
        console.log(products, "ooooooo 123");
        let product_quantities = this.state.product_quantities
        let totalPayment = 0;
        let totalItem = 0;
        let discountamount = 0;
        let finalPaymentAfterDiscount = 0;
        products.forEach((product, i) => {
            const price = parseFloat(product.price);
            totalPayment = totalPayment + price * product_quantities[i]?.quantity;
            // totalPayment = totalPayment + price * product.quantity;
            totalItem = totalItem + product_quantities[i]?.quantity;
            // totalItem = totalItem + product.quantity;
        })
        console.log(totalItem, '<<<<<<<<<<');

        const partialMinPayment = totalPayment * (20 / 100);
        let appliedDiscount = 0;
        if (selectedValue.hasOwnProperty("increase_quantity")) {


            appliedDiscount = this.state.discountvalue != "" ? this.state.discountvalue : 0;
            console.log(appliedDiscount, "appliedDiscount if 1002");
        } else {
            console.log(selectedValue, "selectedValue111 12355")
            let value = selectedValue ? selectedValue.name ? selectedValue.name : null : null

            const selectedCoupon = value != null ? couponListFinal.filter(item => item.code === value) : []
            appliedDiscount = selectedCoupon.length > 0 ? selectedCoupon[0].value != "" ? selectedCoupon[0].value : 0 : 0;

            console.log(appliedDiscount, "appliedDiscount else 1002");
        }
        if (appliedDiscount != 0) {

            discountamount = totalPayment * (appliedDiscount / 100);
            finalPaymentAfterDiscount = totalPayment - discountamount;
            console.log(finalPaymentAfterDiscount, "finalPaymentAfterDiscount if 1002");
        } else {
            discountamount = 0;
            finalPaymentAfterDiscount = totalPayment;
            console.log(finalPaymentAfterDiscount, "finalPaymentAfterDiscount else 1002");

        }

        const deliveryPayment = totalPayment - partialMinPayment;

        console.log(totalPayment, partialMinPayment, deliveryPayment, totalItem, discountamount, finalPaymentAfterDiscount, appliedDiscount, 'paymentorder 1002');

        this.setState({ paymentOrder: { totalPayment, partialMinPayment, deliveryPayment, totalItem, discountamount, finalPaymentAfterDiscount, appliedDiscount } })
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.products != this.state.products) {
            this.productPriceCalCulate();
        }
    }

    componentDidMount = async () => {
        console.log("12345566");
        const allOrders = this.props.OrderReducer.selectedOrders;

        const pagename = this.props.CustomerReducer.pagename;
        console.log(pagename, "pagename");
        console.log(allOrders, "reorderProducts")
        const reorderProducts = allOrders ? allOrders.products : [];
        console.log(allOrders, 123456666);
        let product_quantities = []
        let finalData = [];
        const newProductQuantity = {}
        let payload = {
            type: "productlist"
        }
        ProductService.getProductList(payload).then(res => {
            console.log(res, "response1233")
            // return
            if (res && res.data && res.data.success) {
                if (res.data.data) {
                    const products = res.data.data;
                    for (let i = 0; i < products.length; i++) {
                        let product = products[i]
                        console.log(product, 999);
                        newProductQuantity[product._id] = product.quantity;
                    }
                    for (let i = 0; i < products.length; i++) {

                        if (reorderProducts !== undefined && reorderProducts !== null && Object.keys(reorderProducts).length > 0) {
                            console.log(reorderProducts, "reorderProducts")
                            console.log(products, "reorderProductsproducts")
                            const filteredItem = reorderProducts.find(item => item.product_id === products[i]._id);
                            console.log(filteredItem, "reorderProductsfilteredItem")
                            if (filteredItem) {
                                console.log(filteredItem, 'filteredItem');
                                product_quantities.push({
                                    item: i + 1,
                                    quantity: filteredItem.quantity
                                })
                            } else {
                                product_quantities.push({
                                    item: i + 1,
                                    quantity: 0
                                })
                            }
                        } else {
                            product_quantities.push({
                                item: i + 1,
                                quantity: 0
                            })
                        }


                    }
                    this.setState({ products: products, productQuantity: newProductQuantity, product_quantities })
                }
            }
        }).catch(e => {
            console.log("e>>>>>>>>>>>>", e)
        })
        OtherService.getDiscountAmount().then(data => {
            if (data.data.success && data.data.data.response.length > 0) {
                // console.log(data.data.data.response[0],"discountdata");
                // this.setState({ discountvalue: data.data.data.response[0].discountValue });
                this.setState({ discountvalue: 0 });
            }
        })

        OtherService.getCouponList().then(data => {
            console.log(data, "coupon list");
            if (data.data.success && data.data.data.response.length > 0) {
                for (let index = 0; index < data.data.data.response.length; index++) {
                    finalData[index] = {
                        "name": data.data.data.response[index].code,
                        "label": data.data.data.response[index].code,
                        "value": data.data.data.response[index].code,
                        "id": data.data.data.response[index]._id,
                    };
                }
                this.setState({ couponList: finalData, couponListFinal: data.data.data.response }, () => {
                    this.setSchemeCouponList()
                })
            }
        })

        OtherService.getSchemeData().then(data => {
            console.log(data, "data1233");
            if (data.data.success && data.data.data.details.length) {
                let schemeDetails = data.data.data.details
                for (let i = 0; i < schemeDetails.length; i++) {
                    schemeDetails[i]["label"] = schemeDetails[i].name
                    schemeDetails[i]["value"] = schemeDetails[i].name
                }
                this.setState({ schemeDetails }, () => {
                    this.setSchemeCouponList()
                });
                console.log(data.data.data.details, "data1233444");

                // console.log(data.data.data.response[0],"discountdata");
                // this.setState({ discountvalue: data.data.data.response[0].discountValue });
                // this.setState({ discountvalue: 0 });
            }
        })

        CountryService.getCountries().then(data => {
            if (data.success) {
                this.setState({ countries: data.countries })
            }
        })

        setTimeout(() => {
            this.populateStates("IN")
            // this.populateStates(countryObj.isoCode)
        }, 500);
    }

    setSchemeCouponList = () => {
        console.log(this.state.couponList, this.state.schemeDetails, 565656565);
        let schemeCouponList = [...this.state.couponList, ...this.state.schemeDetails]
        this.setState({ schemeCouponList })
    }

    navigateToPrevious() {
        this.props.navigate(-1);
    }

    getCouponCode = async (data) => {
        let code = ""
        if (data && data.hasOwnProperty('scheme_code')) {
            // setTimeout(() => {
            console.log('scheme')
            this.setState({ coupon_code: data.scheme_code })

            // }, 500);


        } else {
            OtherService.getCouponCode(data).then(data => {
                console.log(data.data.data.coupondetails.length, 'coupondetails')
                if (data.data.success && data.data.data.coupondetails.length) {
                    console.log(data.data.data.coupondetails[0].coupon_id, 'coupondetails11')
                    console.log('coupon')
                    let couponid = data.data.data.coupondetails[0].coupon_id;
                    // alert(couponid);
                    // setTimeout(() => {
                    this.setState({ coupon_code: couponid })

                    // }, 500);
                }
            })
        }

        // return code;


    }

    async placeOrder() {
        await this.getCouponCode(this.state.selectedValue);
        const prescription_id = this.props.CustomerReducer.prescription?._id;
        console.log(prescription_id, "prescription");
        // return
        const customer_id = this.props.CustomerReducer.customer?._id;
        const customer = this.props.CustomerReducer.customer;
        console.log(customer, 3333);

        // return
        const { paymentOrder, products, product_quantities, productQuantity, registration, isChecked, freepenQuantity, freepenPrice, isCheckFreePen } = this.state;
        console.log(freepenQuantity, freepenPrice, "freepenQuantity, freepenPrice");
        console.log(productQuantity, "allproductquantity");
        console.log(products, "allproducts");
        console.log(paymentOrder, "allproducts123");
        console.log(product_quantities, "product_quantities");
        console.log(registration.addressLine1, "addressLine1")
        const newProducts = products.map((product, i) => {
            return {
                product_id: product._id,
                price: product.price,
                quantity: product_quantities[i].quantity,
                // quantity: product.quantity,
                name: product.name
            }
        })
        const _products = newProducts.filter(product => product.quantity != 0)

        console.log(_products, "_products");
        const doctorid = customer ? customer.doctor_id : ""


        console.log(this.state.selectedValue, 200000);
        // let selectedData = this.state.selectedValue;

        let couponcode = ''
        let coupontype = ''
        if (this.state.selectedValue && this.state.selectedValue.hasOwnProperty('scheme_code')) {
            // setTimeout(() => {
            console.log('scheme1233')
            couponcode = this.state.selectedValue.scheme_code
            coupontype = 'scheme'
            this.setState({ coupon_code: this.state.selectedValue.scheme_code })

            // }, 500);


        } else {
            // OtherService.getCouponCode(this.state.selectedValue).then(data => {
            //     console.log(data.data.data.coupondetails.length, 'coupondetails')
            //     if (data.data.success && data.data.data.coupondetails.length) {
            //         console.log(data.data.data.coupondetails[0].coupon_id, 'coupondetails11')
            //         console.log('coupon123')
            //         let couponid = data.data.data.coupondetails[0].coupon_id;
            //         // alert(couponid);
            //         // setTimeout(() => {
            //         couponcode = +couponid
            //         this.setState({ coupon_code: couponid })

            //         // }, 500);
            //     }
            // })

            const data = await OtherService.getCouponCode(this.state.selectedValue);
            if (data.data.success && data.data.data.coupondetails.length) {
                couponcode = data.data.data.coupondetails[0].coupon_id;
                coupontype = 'coupon'
                this.setState({ coupon_code: couponcode });
            }
        }

        // alert(couponcode)
        let submittedData = {
            customer_id,
            total: paymentOrder.finalPaymentAfterDiscount,
            applieddiscount: paymentOrder.appliedDiscount,
            discountAmount: paymentOrder.discountamount,
            totalBeforeDiscount: paymentOrder.totalPayment,
            couponCode: this.state.selectedValue ? this.state.selectedValue.name : "",
            couponCodeId: couponcode,
            couponType: coupontype,
            couponDiscount: this.state.couponDiscount,
            products: _products,
            doctor_id: doctorid,
            prescription_id: prescription_id,
            addressLine1: registration.addressLine1,
            pin: registration.pin,
            country: registration.country,
            state: registration.state,
            town: registration.town,
            isChecked: isChecked,
            freepenQuantity: isCheckFreePen ? freepenQuantity : 0,
            freepenPrice: isCheckFreePen ? freepenPrice : 0

        }

        console.log(submittedData, "submittedData");

        let all_products = this.state.products
        let all_quantities = {}
        let product_length = all_products.length
        for (let i = 0; i < product_length; i++) {
            all_quantities[`quantity${i + 1}`] = 0
        }
        for (let i = 0; i < _products.length; i++) {
            if (_products.length === 1) {
                all_quantities[`quantity${i + 1}`] = productQuantity[_products[i].product_id];
                for (let j = 0; j < product_length; j++) {
                    if (j !== 0) {
                        all_quantities[`quantity${i + 1}`] = 0
                    }
                }
            }
            else {
                if (_products[i] && productQuantity[_products[i].product_id]) {
                    all_quantities[`quantity${i + 1}`] = productQuantity[_products[i].product_id];
                }
            }
        }
        console.log(all_quantities, "allquant");
        console.log(customer, 'customer>>>>><<<<<<')
        //////////////////////////ayan///////////////////////////////////
        // submittedData = {
        //     ...submittedData,
        //     doctor_id: "64d51d951ed9ac3e2948c3f7",
        //     prescription_id: "64d51d951ed9ac3e2948c3e9",
        //     products: [{
        //         product_id: "65249d69d0f0d5b703cc417a",
        //         price: 3280,
        //         quantity: 10,
        //         name: "EMROK - Injection (I.V) 800mg/100ml"
        //     }],
        //     total: 32800,
        //     totalBeforeDiscount: 32800
        // }

        //////////////////////////ayan////////////////////////////////////
        console.log(submittedData, 'bbbbbbbbbbbbbbbbb>>>>><<<<<<')
        console.log(registration, "registration");
        // return
        if (!submittedData.products.length) {
            this.props.hoc.customAlert("Please select atleast 1 product", false)
        }
        else if (isChecked == true && registration.addressLine1 == "") {
            this.props.hoc.customAlert("Address is required", false)
            return false
        } else if (isChecked == true && registration.pin == "") {
            this.props.hoc.customAlert("Pin Code is required", false)
            return false
        } else if (isChecked == true && registration.country == "") {
            this.props.hoc.customAlert("Country is required", false)
            return false
        } else if (isChecked == true && registration.state == "") {
            this.props.hoc.customAlert("State is required", false)
            return false
        } else if (isChecked == true && registration.town == "") {
            this.props.hoc.customAlert("Town is required", false)
            return false
        } else {
            CustomerService.createOrder(submittedData).then(res => {
                if (res && res.data && res.data.success && res.data.data) {
                    CallCenterService.showLoader()
                    console.log("order details", res.data.data[0]);
                    let orderContent = {
                        _id: res.data.data[0]._id
                    }
                    this.props.placeOrder(res.data.data[0]);
                    this.setPaymentDetails({ order_id: res.data.data[0].order_id, amount: res.data.data[0].total })
                    //return
                    //this.setState({ isOrderplaced: true, orderDetails: res.data.data[0] })

                    // OtherService.getOrderDetailsOut().then(async res => {
                    //     // //console.log(res, 7878);
                    //     if (res && res.data && res.data.success) {
                    //         //console.log(res, 123456);
                    //     }
                    // }).catch(err => {
                    //     //console.log(err)
                    // })


                    // this.props.hoc.customAlert("Town is required", false)
                    // this.props.navigate('/customer/payment');
                }
            }).catch(err => {
                CallCenterService.hideLoader()
                console.log(err, 67676776)
            })
        }
        //this.props.navigate('/customer/payment');
    }
    productIncrement = async (productId) => {
        const { products, productQuantity, schemeDetails, isCheckFreePen, selectedValue } = this.state;

        console.log(products, "productsproductQuantity");
        console.log(productQuantity, "productQuantity")
        let product_quantities = this.state.product_quantities
        console.log(product_quantities, "product_quantitiesproductQuantity");
        const newProducts = [...products]
        const productIndex = products.findIndex((product) => {
            return product._id == productId
        })

        console.log(productIndex, "productIndexproductQuantity")

        console.log(products[productIndex], "productdetailss12333productQuantity");
        console.log(products[productIndex].quantity, "productIndexproductQuantity");
        console.log(productQuantity, ">>>>>>productQuantity")
        console.log(product_quantities, "655555productQuantity");
        console.log(productIndex, "655555productQuantity11");
        console.log(product_quantities, productIndex, product_quantities[productIndex].quantity, "655555productQuantity");
        if (products[productIndex].material == "403877") {
            // console.log('1_productQuantity');

            if (product_quantities[productIndex].quantity < productQuantity[productId] && product_quantities[productIndex].quantity < 10) {
                newProducts[productIndex].quantity = product_quantities[productIndex].quantity += await 5
                this.setState({ product_quantities, products: newProducts, reload: !this.state.reload })
            }
        } else {
            // console.log('2_productQuantity')
            console.log(product_quantities[productIndex].quantity, productQuantity[productId], "jkhjhjk");
            newProducts[productIndex].quantity = product_quantities[productIndex].quantity += await 5
            this.setState({ product_quantities, products: newProducts, reload: !this.state.reload })
            if (product_quantities[productIndex].quantity > productQuantity[productId] || productQuantity[productId] <= PRODUCT_LIMIT) {
                console.log("2_productQuantity");
                this.props.hoc.customAlert("Product stock is not available.", false)
                newProducts[productIndex].quantity = product_quantities[productIndex].quantity -= await 5
                this.setState({ product_quantities, products: newProducts, reload: !this.state.reload })
            }



            // if (isCheckFreePen) {
            //     let productSelectedQuantity = product_quantities.some(item => item.quantity > 0);

            //     if (productSelectedQuantity) {
            //         this.setState({ freepenQuantity: FREE_PEN_QUANTITY, freepenPrice: FREE_PEN_PRICE })
            //     } else {
            //         this.setState({ freepenQuantity: 0, freepenPrice: 0 })
            //     }
            //     console.log(productSelectedQuantity, "productSelectedQuantity");

            // } else {
            //     this.setState({ freepenQuantity: 0, freepenPrice: 0 })
            // }
            // this.setState({ isCheckFreePen: isCheckFreePen })
            // this.productPriceCalCulate();
            if (selectedValue.hasOwnProperty("increase_quantity")) {
                this.calculateScheme()
            } else {
                this.productPriceCalCulate()

                setTimeout(() => {
                    this.calculateCoupon()

                }, 500);
            }

        }

    }

    productDecrement = async (productId) => {
        const { products, schemeDetails, selectedValue } = this.state;
        let product_quantities = this.state.product_quantities
        console.log(products, 'aaaaaaaaaaaa')
        const newProducts = [...products]
        const productIndex = products.findIndex((product) => {
            return product._id == productId
        })

        if (products[productIndex].material == "403877") {
            if (product_quantities[productIndex].quantity > 0) {
                newProducts[productIndex].quantity = product_quantities[productIndex].quantity -= await 5
                this.setState({ product_quantities, products: newProducts, reload: !this.state.reload })
            }
        } else {
            if (product_quantities[productIndex].quantity > 0) {
                newProducts[productIndex].quantity = product_quantities[productIndex].quantity -= await 5
                this.setState({ product_quantities, products: newProducts, reload: !this.state.reload })

                if (selectedValue.hasOwnProperty("increase_quantity")) {
                    this.calculateScheme()
                } else {
                    this.productPriceCalCulate()

                    setTimeout(() => {
                        this.calculateCoupon()

                    }, 500);
                }
            }
        }



        // const materialId = products ? products[0].material : null;
        // const selectedQuantity = product_quantities ? product_quantities[0].quantity : 0;
        // const selectedProduct = schemeDetails.find((schemeDetail) => {
        //     return schemeDetail.material == materialId && selectedQuantity >= schemeDetail.purchase_quantity
        // })
        // if (selectedProduct) {
        //     let total_free_quantity = (Math.floor(product_quantities[productIndex].quantity / selectedProduct.purchase_quantity)) > 0 ? (Math.floor(product_quantities[productIndex].quantity / selectedProduct.purchase_quantity)) * selectedProduct.free_quantity : 0
        //     let total_sum = total_free_quantity > 0 ? total_free_quantity * selectedProduct.price : 0
        //     this.setState({ freepenQuantity: total_free_quantity, freepenPrice: total_sum })
        // } else {
        //     this.setState({ freepenQuantity: 0, freepenPrice: 0 })
        // }
        // console.log(product_quantities, "product_quantities");
    }

    handleCheckbox = () => {
        console.log('checkbox');
        const currentVal = !this.state.isChecked
        this.setState({ isChecked: currentVal });
    }

    changeVal = async (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const registration = { ...this.state.registration };
        registration[name] = value;
        this.setState({ registration: registration });
    }

    changeDropdownValue = (event) => {

        // alert(event.value);
    }
    setStateRegistrationData = (data) => {
        this.setState({ registration: data });

    }
    populateStates = (isoCode = null) => {
        if (isoCode) {
            CountryService.getStates(isoCode).then(data => {
                //console.log(data, "states")
                if (data.success) {
                    this.setState({ states: data.states, towns: [] }, async () => {
                        this.setState({ showTowns: false })
                        let _state = this.state.registration.state || null
                        console.log(_state, this.state.states, "jhgjhgjhg");
                        if (_state) {
                            let stateObj = null
                            for (let i = 0; i < this.state.states.length; i++) {
                                if (this.state.states[i].name === _state) {
                                    stateObj = this.state.states[i]
                                }
                            }
                            console.log(stateObj, _state, 1234545677);
                            console.log("stateObj found", stateObj)
                            // if (stateObj) {
                            setTimeout(() => {
                                this.populateTowns(stateObj.countryCode, stateObj.isoCode)
                            }, 500);
                            // }
                        }
                    })
                }
            })
        }
    }

    populateTowns = (countryCode = null, stateCode = null) => {
        console.log(countryCode, stateCode, "countryCode stateCode")
        if (countryCode && stateCode) {
            CountryService.getTowns(countryCode, stateCode).then(data => {
                console.log(data, "towns")
                // setTimeout(() => {
                if (data.success) {
                    this.setState({ towns: data.towns }, () => this.setState({ showTowns: true }))
                }
                // }, 2000);
            })
        }
    }

    setPaymentDetails = (payload) => {

        console.log(payload, "paymentDetails");
        CCAvenueService.generateChecksum(payload).then(data => {
            console.log(data, "sdfsd");
            if (data.success) {
                setTimeout(() => {
                    CallCenterService.hideLoader()
                    $("#nonseamless").submit()
                }, 2000);
            }
            else {
                CallCenterService.hideLoader()
            }
        })
    }

    handleFreePenCHeck = () => {
        let product_quantities = this.state.product_quantities
        let isCheckFreePen = !this.state.isCheckFreePen
        console.log(product_quantities, 'aaaaaaaaaaaaproduct_quantities')

        if (isCheckFreePen) {
            let productSelectedQuantity = product_quantities.some(item => item.quantity > 0);

            if (productSelectedQuantity) {
                this.setState({ freepenQuantity: FREE_PEN_QUANTITY, freepenPrice: FREE_PEN_PRICE })
            } else {
                this.setState({ freepenQuantity: 0, freepenPrice: 0 })
            }
            console.log(productSelectedQuantity, "productSelectedQuantity");

        } else {
            this.setState({ freepenQuantity: 0, freepenPrice: 0 })
        }
        this.setState({ isCheckFreePen: isCheckFreePen })
    }

    render = () => {
        const { products, productQuantity, paymentOrder, product_quantities, couponList, couponDiscount, isShowApplyBtn, isChecked, registration, countries, country, state, town, states, towns, showTowns, isOrderplaced, orderDetails, paymentDetails, isCheckFreePen, freepenQuantity, freepenPrice, schemeCouponList, titleName } = this.state;
        console.log(products, "kajshdjkhas"); console.log(productQuantity, "kajshdjkhas");
        console.log(this.state, 999999);
        console.log(country, 'country');
        console.log(isOrderplaced, "isOrderplaced");

        return (
            <>
                <section className="place-order-container">
                    <div className="container">
                        <CustomerHeader />
                        {/* Row End*/}
                        <div className="row">
                            <div className="col-lg-12">
                                {/* Title end */}
                                {/* Product details content box end*/}
                                {/* Product details content box end*/}
                                {/* commented by ayan */}
                                <ProductList paymentOrder={paymentOrder} products={products} productQuantity={productQuantity} productIncrement={this.productIncrement} productDecrement={this.productDecrement} product_quantities={product_quantities} reload={this.state.reload} />
                                {/* <div className="row mt-4">
                                    <div className='col-md-1 checkboxstyle'>
                                        <input type="checkbox" className='form-label' onChange={() => this.handleFreePenCHeck()} checked={isCheckFreePen} />
                                    </div>
                                    <div className='col-md-10 consenttext' >
                                        <p>Please confirm if you want a Free Pen worth Rs.800/- with your Order.</p>
                                    </div>
                                </div> */}

                            </div>
                            <div className="col-lg-6">
                                {/* commented my ayan */}
                                <OrderList productListCompRef={this.productListCompRef} products={products} paymentOrder={paymentOrder} placeOrder={this.placeOrder} product_quantities={product_quantities} couponList={couponList} selectedValue={this.state.selectedValue} onValueChange={this.handleSelectChange} applyCoupon={this.applyCoupon} couponDiscount={couponDiscount} isShowApplyBtn={isShowApplyBtn} removeCoupon={this.removeCoupon} handleCheckbox={this.handleCheckbox} isChecked={isChecked} changeVal={this.changeVal} addressLine1={registration.addressLine1} pin={registration.pin} countries={countries} country={registration.country} states={states} towns={towns} town={registration.town} state={registration.state} setStateRegistrationData={this.setStateRegistrationData} populateStates={this.populateStates} populateTowns={this.populateTowns} showTowns={showTowns} registration={registration} freepenQuantity={freepenQuantity} freepenPrice={freepenPrice} isCheckFreePen={isCheckFreePen} schemeCouponList={schemeCouponList} titleName={titleName} />
                            </div>
                        </div>
                        {/* Row end*/}


                    </div>

                </section>
                {
                    isOrderplaced &&
                    <OrderPlacedModal currentOrder={orderDetails} navigateUrl={"/order-information"} />
                }

                {/* Logout btn top section end */}
                {
                    paymentDetails && paymentDetails.dataObj &&
                    <form id="nonseamless" method="post" name="redirect" action={config.ccavenueRedirectURL}>
                        <input type="hidden" id="encRequest" name="encRequest" value={paymentDetails.dataObj.encRequest} />
                        <input type="hidden" name="access_code" id="access_code" value={paymentDetails.dataObj.access_code} />
                        <script language="javascript">document.redirect.submit();</script>
                    </form>
                }
                <CustomerFooter />
            </>
        )

    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        placeOrder: (order) => dispatch(OrderAction.placeOrder(order))
    }
}

const mapStateToProps = (state) => {
    console.log(state, "kjdskjfksd");
    return {
        // dispatching plain actions
        CustomerReducer: state.CustomerReducer,
        CommonReducer: state.CommonReducer,
        ProductReducer: state.ProductReducer,
        OrderReducer: state.OrderReducer,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(PlaceOrder)))