import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from '../../../components/Reusable/WithRouter';
import CustomerHeader from "../../../components/layout/CustomerHeader";
import TrackShipment from '../../../components/Order/TrackShipment';
import ShippingAddress from '../../../components/Order/OrderShippingAddress';
import OrderService from '../../../services/Order.service';
import config from '../../../config/emrok.config';
import CustomerFooter from '../../../components/layout/CustomerFooter';
import Common from '../../../hoc/Common.hoc';
import axios from 'axios';
import OtherService from '../../../services/OtherService';
import BackButton from '../../../components/Reusable/BackButton';
class OrderInformation extends Component {
    constructor(props) {
        super(props)

        this.state = {
            quantity: "",
            quantity1: 0,
            quantity2: 0,
            selectOrder: [],
            bankrefno: "",
            rtochangeDate: "",
            refundchangeDate: "",

        }
    }

    checkQuantity() {
        const selectOrder = this.props.OrderReducer.selectedOrders;
        console.log(selectOrder, "seelct order");
        let quantity = 0;
        selectOrder.products.forEach((order, i) => {
            if (i === 0) {
                this.setState({ quantity1: order.quantity })
            }
            else {
                this.setState({ quantity2: order.quantity })
            }
            quantity = quantity + order.quantity;
        })

        let fre_pen_quanity = selectOrder.free_pen_quantity ? selectOrder.free_pen_quantity : 0;
        quantity = quantity + fre_pen_quanity;
        this.setState({ quantity })
    }
    componentDidMount() {
        const allOrders = this.props.OrderReducer;
        this.setState({ selectOrder: allOrders });
        console.log(allOrders, "selectedOrders");
        this.checkQuantity();
        const order_id = allOrders.selectedOrders ? allOrders.selectedOrders.order_id : "";
        console.log(order_id, "order_id")
        if (order_id != "") {

            OtherService.getTransactionDetails({ order_id: order_id }).then(data => {
                // console.log(data.data.data[0],"alladata")
                if (data.data.success) {

                    // console.log(data.data.data[0].bank_ref_no,"data12345");


                    if (data.data.data.length > 0) {
                        console.log(data.data.data[0], "alladata123")
                        this.setState({ bankrefno: data.data.data[0].bank_ref_no })
                    }


                }
                else {
                    // alert("Something went wrong.")
                }
            })


            OtherService.getStatusTrack({ order_id: order_id }).then(response => {

                if (response.data.success) {
                    console.log(response, "statustrack")

                    const changeDate = response.data.data[0].change_date;
                    if (changeDate != "" && response.data.data[0].status == "RTO") {
                        this.setState({ rtochangeDate: changeDate })
                    }
                    if (changeDate != "" && response.data.data[0].status == "Refund Completed") {
                        this.setState({ refundchangeDate: changeDate })
                    }
                    // console.log(changeDate,"changeDate")
                }
            })

        }


    }

    orderItemListJsx = () => {
        const selectOrder = this.props.OrderReducer.selectedOrders;

        const { quantity, quantity1, quantity2 } = this.state;
        return (
            <>
                {
                    selectOrder.products.map((product, i) => {
                        return (
                            <>
                                <h6>
                                    <div className="tablts-dtls">
                                        <div>{product.name}</div>
                                        <div>₹{product.price}  {i === 0 ? " x " + quantity1 : " x " + quantity2}</div>
                                    </div>
                                </h6>
                            </>
                        )
                    })
                }

            </>
        )
    }

    orderCancel = () => {
        console.log(this.props.OrderReducer, "this.props.OrderReducer");

        this.props.hoc.customAlert('Are you sure you want to proceed?', true, "", true, true, () => {


            if (this.props.OrderReducer.selectedOrders && this.props.OrderReducer.selectedOrders._id) {
                let payload = {
                    order_id: this.props.OrderReducer.selectedOrders._id,
                    status: "cancelled"
                }
                OrderService.updateOrderStatus(payload).then(data => {
                    if (data.success) {
                        this.props.hoc.customAlertWithClick("Your order is cancelled ", true, 'Thank you')
                        // this.props.navigate('/customer/registration');
                    }
                    else {
                        alert("Something went wrong.")
                    }
                })

            }



        }, () => {

        })

    }

    handleDownload = async () => {

        const selectOrder = this.props.OrderReducer.selectedOrders;

        const downloadUrl = config.serviceUrl + "/images/sap/in/Invoice/" + selectOrder.order_id + ".pdf"; // Replace with the actual URL or path of the file
        try {
            const response = await fetch(downloadUrl);
            if (response.status === 200) {
                window.open(downloadUrl, '_blank');
                console.log("present")
            } else {
                // alert("not present")
                this.props.hoc.customAlert("Invoice not yet generated.", false)
                console.log("not present")
            }
        } catch (error) {
            window.open(downloadUrl, '_blank');
            // Handle network or other errors
            console.log("error");
        }

    };

    render = () => {
        const selectOrder = this.props.OrderReducer.selectedOrders;

        console.log(selectOrder, "selectOrder");
        const { quantity, quantity1, quantity2, bankrefno, refundchangeDate, rtochangeDate } = this.state;
        const customer = this.props.OrderReducer.selectedOrders.customer;
        console.log("?>>>>>>", customer)
        return (
            <>
                <section className="order-infrmtn-pt pt-0 order-information-container">
                    <div className="container">
                        <CustomerHeader />
                        {/* Row end */}
                        <div className="row">
                            <div className="ordr-info-part mt-4">
                                <h2>Order Information</h2>
                                <p>
                                    <span className="ordr-fst-id">Order ID</span>
                                    <span className="ordr-num"> {selectOrder.order_id ? selectOrder.order_id : selectOrder._id}</span>
                                </p>
                            </div>
                            <div className="col-md-6 price-details-pt">
                                <div className="price-details-dtls mt-4">
                                    <h5>Price Details</h5>
                                    <h4>Total Quantity ({quantity})</h4>
                                    {
                                        this.orderItemListJsx()
                                    }
                                    <hr />
                                    <h6>
                                        <div className="tablts-dtls">
                                            <div>Gross Total</div>
                                            <div>₹{selectOrder.totalBeforeDiscount ? (parseFloat(+selectOrder.totalBeforeDiscount)).toFixed(2) : 0}</div>
                                        </div>
                                    </h6>
                                    <h6>
                                        <div className="tablts-dtls">
                                            <div>Free Gift ({selectOrder.free_pen_quantity ? selectOrder.free_pen_quantity : 0})</div>
                                            <div>₹{selectOrder.free_pen_price ? selectOrder.free_pen_price : 0}</div>
                                        </div>
                                    </h6>
                                    <h6>
                                        <div className="tablts-dtls">
                                            <div>Scheme/Coupon Applied ({selectOrder.couponCode})</div>
                                            <div>₹{selectOrder.couponDiscount ? (parseFloat(+selectOrder.couponDiscount)).toFixed(2) : 0}</div>
                                        </div>
                                    </h6>
                                    {/* <h6>
                                        <div className="tablts-dtls">
                                            <div>Tax</div>
                                            <div>₹00</div>
                                        </div>
                                    </h6> */}
                                    <hr />
                                    <h6>
                                        <div className="tablts-dtls">
                                            <div>Total Amount</div>
                                            {selectOrder.payment_type == "cod" ? <div>₹00</div> : <div>₹{selectOrder.total ? (parseFloat(+selectOrder.total)).toFixed(2) : 0}</div>}
                                        </div>
                                        {/* <div className="tablts-dtls">
                                            <div>Amount Paid</div>
                                            <div>₹00</div>
                                        </div> */}
                                    </h6>
                                    {/* <h6>
                                        <div className="tablts-dtls">
                                            <div>Due Amount</div>
                                            {selectOrder.payment_type == "cod" ? <div>₹{selectOrder.total ? (parseFloat(+selectOrder.total)).toFixed(2) : 0}</div> : <div>₹00</div>}
                                        </div>
                                    </h6> */}
                                    <p className='notetext'>* Price Inclusive of Taxes</p>
                                    <hr />
                                </div>
                                <ShippingAddress customer={customer} currentOrder={selectOrder.payment_type} bankrefno={bankrefno} selectOrder={selectOrder} />
                            </div>
                            <TrackShipment orderCancel={this.orderCancel} currentOrder={selectOrder} orderplacedDate={selectOrder ? selectOrder.createdAt : ""} orderPickUpdate={selectOrder.awbtrack ? selectOrder.awbtrack.ShipmentPickupDate : ""} awbno={selectOrder.awbtrack ? selectOrder.awbtrack.AWBNo : ""} orderConfirmdDate={selectOrder.confirm_date ? selectOrder.confirm_date : ""} rtochangeDate={rtochangeDate} refundchangeDate={refundchangeDate} />
                            <div className="dwn-btn-trk mt-4 dwn-btn-trk-wrap">
                                <div className="dwn-align-prft">
                                    {/* <button
                                        type="button"
                                        className="feedbk-wrt"
                                        onClick={() => {
                                            console.log(this.props, 898);
                                            this.props.navigate("/customer/feedback")
                                        }}
                                    >
                                        Write Feedback
                                    </button> */}
                                    <BackButton className="btn payment-dtls-submit px-4 orderinfo-backbtn" />
                                    <a
                                        type="button"
                                        className="feedbk-wrt downloadinvoice"
                                        onClick={this.handleDownload}
                                        // href={`${config.serviceUrl}/images/invoice/${selectedOrders.order_id}.pdf`}
                                        // download="download"
                                        target='_blank'
                                    >
                                        Download Invoice
                                    </a>
                                </div>
                                <div>
                                    {/* <a href={config.knowMoreLink} className="knw-mr-track" target="_blank" rel="noopener noreferrer">
                                        Know more
                                    </a> */}
                                </div>
                            </div>
                            <CustomerFooter />
                        </div>
                    </div>
                </section>
            </>
        )

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        // setCustomer: (customer) => dispatch(CustomerAction.setCustomer(customer))
    }
}

const mapStateToProps = (state) => {
    return {
        OrderReducer: state.OrderReducer,
        CustomerReducer: state.CustomerReducer
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(OrderInformation)))